
















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: { ORegistrationHeaderList: () => import('@/components/organisms/globalTools/registrationHeader/o-registration-header-list.vue')},

  setup(){

    return {}
  }
})
